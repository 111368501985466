// import Swiper JS
// import(/* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ 'root/utils/swiper.js');

import { viewport } from "root/utils/viewport";
import Tab from "root/utils/tab";
import $ from "jquery";

let scrollLoad = document.querySelector(".scroll-more");
if (scrollLoad) {
  let headerHeight = 70;
  if (viewport == "desktop") {
    document.body.addEventListener("wheel", (event) => {
      let delta = Math.sign(event.deltaY);
      if (delta > 0) {
        window.scrollTo({
          top: window.pageYOffset + window.outerHeight - headerHeight,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
    if (document.body.classList.contains("home")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
}

const hometab = new Tab(".home_tab .tab-container");

function socialWindow(url, width, height) {
  var left = (screen.width - width) / 2;
  var top = (screen.height - height) / 2;
  var params =
    "menubar=no,toolbar=no,status=no,width=" +
    width +
    ",height=" +
    height +
    ",top=" +
    top +
    ",left=" +
    left;
  window.open(url, "", params);
}

const sharedPost = (e) => {
  let pageUrl = encodeURIComponent(document.URL);
  let url = null;

  if (e.target.classList.contains("facebook")) {
    url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
    socialWindow(url, 570, 570);
  }

  if (e.target.classList.contains("linkedin")) {
    url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
    socialWindow(url, 570, 570);
  }

  if (e.target.classList.contains("twitter")) {
    url = "https://twitter.com/intent/tweet?url=" + pageUrl;
    socialWindow(url, 570, 570);
  }
};

const listSharedButton = document.querySelectorAll(".shared-post-button");
listSharedButton.forEach((item) => (item.onclick = sharedPost));

// Update current item class
function setActiveListElements(wrapperClass) {
  if (!wrapperClass) return;
  var windowPos = $(window).scrollTop();
  $(`.${wrapperClass} a[href^="#"]`).each(function () {
    var currentLink = $(this);
    if ($(currentLink.attr("href")).length > 0) {
      var refElement = $(currentLink.attr("href"));
      if (
        refElement.offset().top - 150 <=
        windowPos
        // refElement.position().top +
        // 	refElement.height() +
        // 	$(".properties-detail").height() >
        // 	windowPos
      ) {
        $(`.${wrapperClass} a[href^="#"]`).removeClass("current");
        currentLink.addClass("current");
      } else {
        currentLink.removeClass("current");
      }
    }
  });
}

// Update menu item on scroll
$(window).scroll(function () {
  // Call function
  const propertiesCat = $(".properties-detail").length;
  const newsCat = $(".post-details-content").length;
  let wrapperClass;
  if (propertiesCat) {
    wrapperClass = "properties-detail";
  } else if (newsCat) {
    wrapperClass = "post-details-content";
  }
  setActiveListElements(wrapperClass);
});
